.process-initializer-wrap {
  background-color: whitesmoke;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 100px;
}

.process-initializer-content {
  min-height: 100vh;
  background-color: white;
}

.stepper-wrap {
  padding-top: 10px;
}

.step-pane-wraps {
  width: 100%;
  max-width: 950px;
  margin: 0px auto;
  padding-bottom: 200px;
}

.step-pane-buttons-wrap {
  margin-top: 80px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (min-width: 501px) {
  .step-pane-wraps {
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 500px) {
  .step-pane-wraps {
    padding: 20px 5px;
  }
}