:root {
    --red: #ff5000 !important;
    --salmon: #ffdbd0;
    --pink: #ffdbd0;
    --beige: #f7f0f0;
    --white: rgb(247, 239, 240);
    --material-border: 0.5px solid rgb(233, 233, 233);
}

*:focus {
    outline: none;
}

.MuiStepper-root {
    padding: 24px 2px !important;
}

.hoverable:hover {
    cursor: pointer;
}