img {
    width: 80%;

    object-fit: contain;
    object-position: center;
}

.unselected-signature {
    margin-top: 20px;
    padding: 2px;
    border: 2px solid rgb(72, 72, 72) !important;
}


.selected-signature {
    margin-top: 20px;
    padding: 4px;
    border: 4px solid var(--purple) !important;
}