.introduction-text-wrapper {
    width: 100%;
    text-align: left;
}


#property-address {
    font-size: 1.25rem;
}

#introduction-text {
    font-size: 1.3rem;
}

#commence-process {
    font-size: 18px;
    font-weight: 600;
    margin-top: 60px;
}